<script lang="ts" setup>
import type { GetImageResult } from 'astro'
import { ref, watch } from 'vue'
import { testingType } from '../stores'
import VideoFrame from '@/components/product-shared/VideoFrame'
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core'

const $videos = ref<HTMLVideoElement[]>([])

watch(testingType, (type) => {
  $videos.value.forEach((video) => {
    const isPlaying =
      video.currentTime > 0 && !video.paused && !video.ended && video.readyState > video.HAVE_CURRENT_DATA
    if (video.dataset.type === type) {
      if (!isPlaying) {
        video.currentTime = 0
        video.play()
      }
    } else {
      if (isPlaying) {
        video.pause()
      }
    }
  })
})

defineProps<{
  windows: {
    type: string
    url: string
    image: GetImageResult
    alt: string
    sources?: {
      srcset: string
      media?: string
    }[]
  }[]
}>()

const { md } = useBreakpoints(breakpointsTailwind)
const SSR = import.meta.env.SSR
</script>

<template>
  <div class="relative mt-[48px] w-full" style="aspect-ratio: 200/109">
    <VideoFrame
      v-for="{ type, url, image, alt, sources = [] } in windows"
      :key="type"
      :size="!SSR && md ? 'lg' : 'sm'"
      :window-urls="[url]"
      class="absolute bottom-0 left-[16px] right-[16px] top-0 shadow-asset"
      :style="{
        transform: type === testingType ? undefined : 'scaleY(0.93) scaleX(1.03) translateZ(-10px)',
        zIndex: type === testingType ? 100 : 0,
        transition: 'transform 0.5s ease-in-out, z-index 0.5s ease-in-out',
      }"
      frame="browser"
    >
      <picture>
        <source v-for="source in sources" :key="source.srcset" v-bind="source" />
        <img
          :src="image.src"
          :width="image.attributes.width"
          :height="image.attributes.height"
          :alt="alt"
          class="rounded-b-lg"
        />
      </picture>
    </VideoFrame>
  </div>
</template>
